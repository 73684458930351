<template>
  <div
    class="search-filter"
    ref="searchFilter"
    v-clickOutside="toggleSearchFilter"
  > 
    <div class="search-filter__content">
      <form class="search-filter__form" @submit.prevent="search()">
        <div class="search-filter__title">Apply Filter</div> 
        <div class="search-filter__row">
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.companyTypes"
              placeholder="Type"
              label="type"
              @input="companyTypes = $event"
              :reduce="(type) => type.id"
              :selected="companyTypes"
            />
          </div>
          <div class="search-filter__column" v-if="$route.name === 'Customers'">
            <MultiSelectDropdown
              :items="resource.companies"
              placeholder="Company"
              label="name"
              @input="companies = $event"
              :reduce="(company) => company.id"
              :selected="companies"
              v-if="$route.name === 'Customers'"
            />
          </div>
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.branchCategories"
              placeholder="Category"
              :label="`long_${language}`"
              @input="branchCategories = $event"
              :reduce="(category) => category.id"
              :selected="branchCategories"
            />
          </div>
        </div>
        <div class="search-filter__row">
          <div class="search-filter__column" v-if="$route.name === 'Customers'">
            <MultiSelectDropdown
              :items="resource.policyStatuses"
              placeholder="Status"
              label="name"
              @input="policyStatuses = $event"
              :reduce="(status) => status.id"
              :selected="policyStatuses"
              v-if="$route.name === 'Customers'"
            />
          </div>
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.agents"
              placeholder="Agent"
              @input="agents = $event"
              :customLabel="(agent) => `${agent.first_name} ${agent.last_name}`"
              :reduce="(agent) => agent.id"
              :selected="agents"
            />
          </div>
          <div
            class="search-filter__column"
            v-if="$route.name === 'PotentialCustomers'"
          >
            <MultiSelectDropdown
              :items="resource.priorities"
              placeholder="Priorities"
              @input="priorities = $event"
              label="type"
              :reduce="(priority) => priority.id"
              :selected="priorities"
            />
          </div>
          <div class="search-filter__column search-filter__column--inputs">
            <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="Birthday"
                v-model="birthday"
              />
            </div>
            <div class="search-filter__column">
              <input
                type="text"
                class="input input--default"
                placeholder="Zip"
                v-model="zip"
              />
            </div>
          </div>
        </div>
        <div class="search-filter__row">
          <div class="search-filter__column search-filter__column--inputs">
            <!-- <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="Start Date"
                v-model="startDate"
              />
            </div> -->
            <div class="search-filter__column" style="margin-right: 10px;">
              <Datepicker
                class="input input--default datepicker" 
                :config="statics.datePickerConfig"
                placeholder="Start Date From"
                v-model="startDateFrom"  
              />
              <div style="margin-bottom: 16px;"></div>
              <Datepicker
                class="input input--default datepicker"
                :config="statics.datePickerConfig"
                placeholder="Start Date To"
                v-model="startDateTo"  
              />
            </div>
            <!-- <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="End Date"
                v-model="endDate"
              />
            </div> -->
            <div class="search-filter__column" style="margin-right: 10px;">
              <Datepicker
                class="input input--default datepicker" 
                :config="statics.datePickerConfig"
                placeholder="End Date From"
                v-model="endDateFrom"  
              />
              <div style="margin-bottom: 16px;"></div>
              <Datepicker
                class="input input--default datepicker"
                :config="statics.datePickerConfig"
                placeholder="End Date To"
                v-model="endDateTo"  
              />
            </div>

            <div class="search-filter__column" style="margin-right: 10px;">
              <Datepicker
                class="input input--default datepicker" 
                :config="statics.datePickerConfig"
                placeholder="Submit Date From"
                v-model="submitDateFrom"  
              />
              <div style="margin-bottom: 16px;"></div>
              <Datepicker
                class="input input--default datepicker"
                :config="statics.datePickerConfig"
                placeholder="Submit Date To"
                v-model="submitDateTo"  
              />
            </div>

            <div class="search-filter__column single-select-column" id="singleSelectColumn" v-if="$route.name === 'Customers'">
              <select 
                class="single-select" 
                name="Terminations" 
                id="terminations" 
                v-model="selectedTermination" 
                @focus="singleSelectOpened" 
                @change="singleSelectClosed" 
                @blur="singleSelectClosed"
                 
              >
                <option value="" disabled>Kündigungen</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select> 
            </div>
             
          </div>
        </div>
        <div class="search-filter__row"> 
            <div class="search-filter__column" v-if="$checkRolePermission('customer_export')"> 
              <a
                  v-show="!isRequesting"
                  type="button"
                  @click="xlsExport()"
                  class="search-export__button button button--default"
              > 
                Export
                <img src="../assets/icons/default/export_files_icon.svg" alt="" />
              </a>
            </div>
             
        </div>
 

        <div
          v-show="isRequesting"
          ref="loadingButton"
          class="search-filter__button button button--default button--loading"
        ></div>
        <button
          v-show="!isRequesting"
          type="button"
          @click="search()"
          class="search-filter__button button button--default"
        >
          Apply Filter
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import statics from "@/statics";
import { mapGetters } from "vuex";
import withPopper from "@/helpers/withPopper";
import MultiSelectDropdown from "@/partials/MultiSelectDropdown";
export default {
  name: "SearchFilter",

  components: { MultiSelectDropdown },

  props: {
    toggleSearchFilter: {
      required: true,
      type: Function,
    },
    filterToggler: {
      required: true,
      type: null,
    },
  },

  directives: {
    clickOutside: {
      bind: (el, binding, vnode) => {
        el.clickEvent = (event) => { 
          if (
            event.target === vnode.context.filterToggler ||
            event.target === vnode.context.$refs.searchFilter || 
            [...el.getElementsByTagName("*")].includes(event.target)  ||
            event.target.closest('.flatpickr-calendar')
          ) {
            vnode.context[binding.expression](true);
          } else {
            vnode.context[binding.expression](false);
          }
        };
        document.addEventListener("click", el.clickEvent);
      },
      unbind: (el) => {
        document.removeEventListener("click", el.clickEvent);
      },
    },
  },

  data() {
    return {
      statics,
      withPopper,
      isRequesting: false,
      birthday: "",
      zip: "", 
      agents: [],
      companyTypes: [],
      companies: [],
      branchCategories: [],
      policyStatuses: [],
      priorities: [],
      searchKeywords: "",
      resource: {},
      export: false,

      selectedTermination: '',

      startDateFrom:"",
      startDateTo: "",

      endDateFrom:"",
      endDateTo: "",

      submitDateFrom:"",
      submitDateTo: "",
    };
  },
  async created() {
    if (this.$route.name === "PotentialCustomers") {
      this.resource = this.searchPotentialCustomerForms;
    } else {
      this.resource = this.searchCustomerForms;
    }
    const {
      search,
      agents,
      companyTypes,
      companies,
      branchCategories,
      policyStatuses,
      priorities,
      birthday, 
      zip,
      startDateFrom,
      startDateTo,
      endDateFrom,
      endDateTo,
      submitDateFrom,
      submitDateTo,
      has_terminations
    } = this.$route.query;
    this.searchKeywords = search || "";
    this.agents = agents ? agents.split(",").map((i) => i) : [];
    this.companyTypes = companyTypes
      ? companyTypes.split(",").map((i) => i)
      : [];
    this.companies =
      companies && this.$route.name === "Customers"
        ? companies.split(",").map((i) => i)
        : [];
    this.branchCategories = branchCategories
      ? branchCategories.split(",").map((i) => i)
      : [];
    this.policyStatuses =
      policyStatuses && this.$route.name === "Customers"
        ? policyStatuses.split(",").map((i) => i)
        : [];
    this.priorities =
      priorities && this.$route.name === "PotentialCustomers"
        ? priorities.split(",").map((i) => i)
        : [];
    this.birthday = birthday || "";
    this.zip = zip || "";


    this.startDateFrom = startDateFrom || "";
    this.startDateTo = startDateTo || "";
    this.endDateFrom = endDateFrom || "";
    this.endDateTo = endDateTo || "";
    this.submitDateFrom = submitDateFrom || "";
    this.submitDateTo = submitDateTo || "";
    this.selectedTermination = has_terminations || '';

 
  }, 
  computed: {
    ...mapGetters([
      "searchCustomerForms",
      "searchPotentialCustomerForms",
      "language",
    ]),

    terminationValue() {
      if(this.selectedTermination === 'true'){
        return true;
      }else if(this.selectedTermination === 'false'){
        return false;
      }
      return ''
    },

  

  },
  watch: {
    startDateFrom(newDate) {
      if ((newDate > this.startDateTo) || !this.startDateTo) {
        this.startDateTo = newDate;
      }
    },
    startDateTo(newDate) {
      if ((newDate < this.startDateFrom) || !this.startDateFrom) {
        this.startDateFrom = newDate;
      }
    },



    endDateFrom(newDate) {
      if ((newDate > this.endDateTo) || !this.endDateTo) {
        this.endDateTo = newDate;
      } 
    },
    endDateTo(newDate) {
      if ((newDate < this.endDateFrom) || !this.endDateFrom) {
        this.endDateFrom = newDate;
      } 
    },




    submitDateFrom(newDate) {
      if ((newDate > this.submitDateTo) || !this.submitDateTo) {
        this.submitDateTo = newDate;
      }  
    },
    submitDateTo(newDate) {
      if ((newDate < this.submitDateFrom) || !this.submitDateFrom) {
        this.submitDateFrom = newDate;
      }  
    }
  },
  methods: {
    singleSelectOpened() { 
      const element = document.getElementById('singleSelectColumn');
      element.classList.add('open');
    },
    singleSelectClosed() { 
      const element = document.getElementById('singleSelectColumn');
      element.classList.remove('open');
    },

    async search() {
      this.isRequesting = true;

      if (this.$route.name === "Customers") { 
        await this.$store.dispatch("searchCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          companies: this.companies,
          branchCategories: this.branchCategories,
          policyStatuses: this.policyStatuses,
          birthday: this.birthday,
          zip: this.zip,
          has_terminations: this.terminationValue,


          startDateFrom: this.startDateFrom, 
          startDateTo: this.startDateTo, 
          endDateFrom: this.endDateFrom,
          endDateTo: this.endDateTo, 
          submitDateFrom: this.submitDateFrom,
          submitDateTo: this.submitDateTo
        });
        this.$router.replace({
          query: {
            search: this.searchKeywords,
            agents: this.agents.join(","),
            companyTypes: this.companyTypes.join(","),
            companies: this.companies.join(","),
            branchCategories: this.branchCategories.join(","),
            policyStatuses: this.policyStatuses.join(","),
            birthday: this.birthday,
            zip: this.zip,
            has_terminations: this.terminationValue,

            startDateFrom: this.startDateFrom, 
            startDateTo: this.startDateTo, 
            endDateFrom: this.endDateFrom,
            endDateTo: this.endDateTo, 
            submitDateFrom: this.submitDateFrom,
            submitDateTo: this.submitDateTo
          },
        });
      } else if (this.$route.name === "PotentialCustomers") {
        await this.$store.dispatch("searchPotentialCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          branchCategories: this.branchCategories,
          priorities: this.priorities,
          birthday: this.birthday,
          startDate: this.startDate,
          endDate: this.endDate,
          zip: this.zip,
        });
        this.$router.replace({
          query: {
            search: this.searchKeywords,
            agents: this.agents.join(","),
            companyTypes: this.companyTypes.join(","),
            branchCategories: this.branchCategories.join(","),
            priorities: this.priorities.join(","),
            birthday: this.birthday,
            startDate: this.startDate,
            endDate: this.endDate,
            zip: this.zip,
          },
        });
      }
      this.isRequesting = false;
    },
    async xlsExport() {
      this.isRequesting = true;
      if (this.$route.name === "Customers") {
        await this.$store.dispatch("searchCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          companies: this.companies,
          branchCategories: this.branchCategories,
          policyStatuses: this.policyStatuses,
          birthday: this.birthday,
          zip: this.zip,
          has_terminations: this.terminationValue,
          export: true
        });
      }
      this.isRequesting = false;

    }
  },
};
</script>

<style scoped lang="scss">
.single-select-column{
  position: relative;
   
}

.single-select{
  border: 1px solid transparent;
  background-color: var(--bg-1);
  box-shadow: 0px 5px 30px var(--shadow-4);
  height: 40px;
  color: var(--color-9);
  padding: 0 10px;
  font-size: var(--muli-text-regular);
  border-radius: var(--radius-1);
  font-family: var(--muli-text-semi-bold);
  // min-width: 200px;
  width: 100%;
  appearance: none;
  
}

#singleSelectColumn::after {
  content: '';
  position: absolute;
  right: 4px;
  top: 8px;
  width: 24px;
  height: 24px;
  background: url("../assets/icons/default/Bold 2px-chevron_down.svg")
  no-repeat center;
  pointer-events: none;
  transition: 0.3s ease;
  
}

#singleSelectColumn.open::after {
  background: url("../assets/icons/default/Bold 2px-chevron_down.svg") no-repeat center; /* Change to your desired icon */
  transform: scaleY(-1);
}


.search-filter {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--bg-1);
  width: 900px;
  padding: 60px;
  z-index: 2;
  box-shadow: 0px 5px 30px var(--shadow-4);
  border-radius: var(--radius-3);
  .search-export__button {
    background: #B9C2CD;
    box-shadow: none;
    height: 40px;
    width: 115px !important;
    padding-right: 10px;
    margin-left: 0px !important;
    padding-left: 10px !important;
    text-align: left;
    position: relative;
    line-height: 38px;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      background: var(--text-5);
    }
    img {
     position: absolute;
      right: 5px;
      width: 27px;
      bottom: 7px;
    }
  }
  &__title {
    color: var(--text-5);
    font-family: var(--muli-text-semi-bold);
    font-size: var(--medium);
    margin-bottom: 50px;
  }

  &__row,
  &__column--inputs {
    display: flex;
  }

  &__row {
    margin-bottom: 40px;
  }

  &__column {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    margin: 60px auto 0 auto !important;
  }
}
</style>
